@tailwind base;
@tailwind components;
@tailwind utilities;

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  scroll-behavior: smooth;
}
.text-black1{
  color:white!important;
}
@media only screen and (max-width: 799px) {
  .text-black1{
    color:black!important;
  }
}
.element {
  background-image: url(https://www.francebleu.fr/s3/cruiser-production/2022/10/42fe4a59-a110-4be2-995e-df59c3866e9c/1200x680_chienchatjpg.jpg);
  background-size: 100% 124%;
}

@media only screen and (max-width: 919px) {
  .element {
    background-size: 180% 124%;
    background-position: 70%;
  }
}
.carousel-root {
  width: 64% !important;
  margin: auto !important;
  margin-top: 3% !important;
}
.faq-content{
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel .slide {
  background: #fff !important;
  color: black;
  height: 100%;
}

.myCarouselImg  {
  width: 80px !important;
  border-radius: 50%;
  margin-inline: auto;
}
.myCarousel {
  background: #fafafa;
  margin-top: -6%;
  width: 54%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  border: 1px solid #ddd;
  height: 286px;
}
.textStyles {
  position: absolute;
  top: 10%;
  left: 3%;
  right: 3%;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.imageStyles {
  width: 100%;
  height: 80%;
  display: block;
}

@media only screen and (max-width: 1049px) {
  .textStyles {
    top: 0%;
  }
  .imageStyles {
    height: auto;
  }
}
.m-3P{
  margin: 0.75rem;
}
.m-3PM{
  font-weight: bold;
  margin: 0.75rem;
}
.carousel .control-dots {
  padding-left: 5px !important;
  outline: 0;
  bottom: 5% !important;
}

.myCarousel h3 {
  color: #222;
  font-weight: 100;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 17px;
}

.myCarousel h4 {
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  font-weight: 500;
  color: #787878;
  font-size: 14px;
}

.myCarousel p {
  font-weight: 100 !important;
  line-height: 29px !important;
  color: #222;
  font-size: 15px;
  font-family: sans-serif;
  max-height: 67px;
}

.myCarousel p:before {
  content: "“";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
}

.myCarousel p:after {
  content: "”";
  color: #aaa;
  font-size: 26px;
  font-family: monospace;
  font-weight: 100;
  line-height: 0;
}

.carousel .control-dots .dot {
  box-shadow: none !important;
  background: #454545 !important;
  outline: 0;
}

.carousel.carousel-slider .control-arrow {
  background: #000 !important;
  height: 50px !important;
  position: absolute;
  top: 35% !important;
}

.App {
  text-align: center;
}
.faq-container {
  text-align: center;
  padding: 20px;
}

.tab-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: center;
  margin: 20px 0;
}

.tab {
  flex: 1;
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.tab.active {
  background-color: #007bff;
  color: white;
}
.newsletter-form__container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.newsletter-form__images img {
  max-width: 100%;
  height: auto;
}

.newsletter-form__content {
  margin-left: 20px;
}

.newsletter-form__block-form__title {
  font-size: 24px;
  font-weight: bold;
}

.newsletter-form__form {
  margin-top: 10px;
}

.form-input {
  display: flex;
  flex-direction: column;
}

.form-input input {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: var(--tw-border-width) solid var(--tw-border-color);
  border-radius: var(--vs-border-radius);
}

.form-input label {
  font-size: var(--vs-font-size);
}

.newsletter-form__cta-button {
  background-color: #007bff;
  color: #fff;
  padding: var(--vs-actions-padding);
  border: none;
  border-radius: var(--vs-border-radius);
  cursor: pointer;
  margin-top: 10px;
}
.subtab-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.faq-text {
  white-space: pre-line;
}
.container-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.centered-div {
  border: 1px solid #ccc; /* Just for demonstration */
  padding: 20px;
}

.right-align-text {
  text-align: left;
}
.subtab {
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 10px;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.subtab.active {
  background-color: #007bff;
  color: white;
}
@media only screen and (max-width: 934px) {
  .carousel-root {
    outline: 0;
    width: 93% !important;
    margin: auto !important;
  }

  .carousel.carousel-slider .control-arrow {
    display: none !important;
  }
  .myCarousel {
    background: #fafafa;
    margin-top: -9%;
    width: 88%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 8%;
    padding-bottom: 12.5%;
    padding-left: 5%;
    padding-right: 5%;
    border: 1px solid #ddd;
    height: 269px;
  }

  .myCarouselImg {
    width: 24% !important;
    border-radius: 50%;
  }
}
.contact-form{
  display: flex;
  justify-content: center;
  align-items: center; 
}
body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    background: #F6F6F5;
    scroll-behavior: smooth;
}
input,select{
  outline: none;
}
.containerProduct {
  display: flex;
  flex-direction: column;
}
.add-button {
  align-self: flex-end;
  margin-bottom: 10px; /* Add spacing between button and products */
}

.products {
  display: flex;
  flex-direction: column;
}
.batch_heart{
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}