/* Testimonials.module.css */

.testimonialsContainer {
    text-align: center;
  }
  
  .heading {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  testimonialCard {
    background: #f8f8f8;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .authorImage {
    width: 100px; /* Adjust the size as needed */
    height: 100px; /* Adjust the size as needed */
    border-radius: 50%; /* To make it a circle */
    margin: 0 auto;
    display: block;
  }
  
  .authorName {
    font-size: 18px;
    margin-top: 10px;
  }
  
  testimonialText {
    font-size: 16px;
    margin-top: 10px;
  }
  
  /* Add more styles as needed */
  